import {graphql, StaticQuery} from "gatsby"
import BackgroundImage from "gatsby-background-image"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import React, {Component, createRef} from "react"
import {
  FaFacebookSquare,
  FaInstagramSquare,
  FaLinkedin,
  FaPodcast
} from "react-icons/fa"
import SEO from "../components/seo"
import "./signing-day-summit.sass"

class SigningDaySummit extends Component {
  constructor() {
    super()
    this.section1Ref = createRef()
  }

  scrollToSection1 = () => {
    this.section1Ref.current.scrollIntoView({
      behavior: "smooth",
      block: "start"
    })
  }

  render() {
    return (
      <>
        <SEO title="College Signing Day Virtual Summit" />
        <StaticQuery
          query={graphql`
            query {
              coverImg: file(relativePath: { eq: "signing-day-summit.jpg" }) {
                sm: childImageSharp {
                  fluid(quality: 100, maxWidth: 576) {
                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                  }
                }
                md: childImageSharp {
                  fluid(quality: 100, maxWidth: 768) {
                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                  }
                }
                lg: childImageSharp {
                  fluid(quality: 100, maxWidth: 992) {
                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                  }
                }
                xl: childImageSharp {
                  fluid(quality: 100, maxWidth: 1200) {
                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                  }
                }
              }
            }
          `}
          render={data => {
            const coverImgSource = [
              data.coverImg.sm.fluid,
              {
                ...data.coverImg.md.fluid,
                media: `(min-width: 768px)`
              },
              {
                ...data.coverImg.lg.fluid,
                media: `(min-width: 992px)`
              },
              {
                ...data.coverImg.xl.fluid,
                media: `(min-width: 1200px)`
              }
            ]
            return (
              <>
                <BackgroundImage
                  fluid={coverImgSource}
                  backgroundColor={`#9aa1a8`}
                  className="summit-hero"
                >
                  <div className="container summit-hero-content">
                    <h1>
                      <span>Want to know</span>
                      <span>how to be successful in college?</span>
                      <span>Wondering what you should</span>
                      <span>know and do before the semester begins?</span>
                      <span>Well, register and attend this Summit.</span>
                    </h1>
                  </div>
                </BackgroundImage>
                {/* ------------------------------------------------------- */}
                <div className="container section summit-section">
                  <div className="summit-section-block-1">
                    <h1>College Signing Day Virtual Summit</h1>
                    <p>
                      In celebration of College Signing Day, I am hosting a
                      virtual seminar teaching you insider strategies
                      from current and former students, faculty, and higher
                      education experts.
                    </p>
                    <p>
                      If you can’t attend the event live, your registration will
                      allow you to receive access to a recording of the full
                      event which you can watch at any time.
                    </p>
                  </div>
                  <div className="summit-section-block-2">
                    <h1>
                      May 1<sup>st</sup> - 10:00 <small>A.M.</small>
                    </h1>
                    <p>
                      <em>Registration is open to first 100 attendees.</em>
                    </p>
                  </div>
                  <div className="summit-section-block-3">
                    <form
                      name="summit-sign-up"
                      id="summit-sign-up"
                      method="POST"
                      data-netlify="true"
                      netlify-honeypot="honeypot"
                      action="/thank-you?no-cache=1"
                    >
                      <input
                        className="hide"
                        type="text"
                        name="form-name"
                        id="netlify-gatsby"
                        placeholder="Don't fill this, it's for Gatsby"
                        value="summit-sign-up"
                      />
                      <input
                        className="hide"
                        type="text"
                        name="honeypot"
                        id="honeypot"
                        placeholder="Don't fill this out if you're human"
                      />
                      <label htmlFor="first-name">Name:</label>
                      <input
                        type="text"
                        name="first-name"
                        id="first-name"
                        placeholder="Phenix"
                        required
                      />
                      <label htmlFor="email-address">Email:</label>
                      <input
                        type="email"
                        name="email-address"
                        id="email-address"
                        placeholder="example@domain.com"
                        required
                      />
                      <button type="submit">See you there</button>
                    </form>
                    <p>
                      <em>
                        Having trouble signing up using the form? Feel free to{" "}
                        <a href="mailto:successcoach@collegecareerstrategy.com?subject=About: College Signing Day Virtual Summit">
                          send me an email
                        </a>
                        .
                      </em>
                    </p>
                  </div>
                  <div className="summit-section-block-4">
                    <p>
                      <strong>
                        This event is brought to you by the College Success
                        Professor
                      </strong>
                    </p>
                    <div className="social-container">
                      <a
                        href="https://www.instagram.com/collegesuccessprofessor/"
                        rel="noopener noreferrer"
                        target="_blank"
                        className="social-icon social-icon-instagram"
                      >
                        <FaInstagramSquare />
                      </a>
                      <a
                        href="https://www.facebook.com/collegesuccessprofessor/"
                        rel="noopener noreferrer"
                        target="_blank"
                        className="social-icon social-icon-facebook"
                      >
                        <FaFacebookSquare />
                      </a>
                      <a
                        href="https://www.linkedin.com/in/frazierbadmus/"
                        rel="noopener noreferrer"
                        target="_blank"
                        className="social-icon social-icon-linkedin"
                      >
                        <FaLinkedin />
                      </a>
                      <AniLink
                        to="/podcast"
                        className="social-icon social-icon-podcast"
                        swipe
                        duration={0.3}
                        entryOffset={100}
                        direction="left"
                      >
                        <FaPodcast />
                      </AniLink>
                    </div>
                  </div>
                </div>
                {/* ------------------------------------------------------- */}
                <div className="spacer-3" />
              </>
            )
          }}
        />
      </>
    )
  }
}
export default SigningDaySummit
